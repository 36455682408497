<template>
  <div class="clock-face">
    <!-- {{ totalTime }} -->
    <p v-if="days > 0">{{ days }} day{{ days == 1 ? "" : "s" }}</p>
    <h1>{{ formattedTime }}</h1>
  </div>
</template>

<script>
const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

import { timeFormat } from "../helpers.js";

export default {
  name: "ClockFace",
  data() {
    return {
      DAY,
      HOUR,
      MINUTE,
      now: Date.now(),
      interval: null,
    };
  },
  props: {
    started_at: Number,
    store: Number,
    ticking: Boolean,
  },
  methods: {
    format(num) {
      num = Math.max(num, 0) + "";
      return num.padStart(2, "0");
    },
  },
  computed: {
    totalTime() {
      if (!this.ticking) {
        return this.store;
      }
      return this.now - this.started_at + this.store;
    },
    totalTimeSeconds() {
      return Math.floor(this.totalTime / 1000);
    },
    days() {
      return Math.floor(this.totalTimeSeconds / DAY);
    },
    formattedTime() {
      return timeFormat(this.totalTimeSeconds);
    },
  },
  mounted() {
    if (!this.interval) {
      this.interval = setInterval(() => (this.now = Date.now()), 250);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clock-face {
  text-align: center;
}
</style>
