<template>
  <header class="sticky-header">
    <router-link to="/">&larr; Back</router-link>
  </header>
  <div class="timer">
    <div v-if="notFound">
      <h1>Not found.</h1>
      <p><router-link to="/">Home</router-link></p>
    </div>
    <div class="delete-page" v-else-if="state == 'deleting'">
      <h1>Delete</h1>
      <p>Obviously, this cannot be undone.</p>
      <div class="button-row">
        <SexyButton @click="state = ''" class="is-secondary">Cancel</SexyButton>
        <SexyButton @click="deleteTimer()" :class="{ 'is-loading': isDeleting }"
          >Confirm</SexyButton
        >
      </div>
    </div>
    <div v-else-if="state == 'editing'">
      <div style="padding: 10px 0; text-align: center;">
        <label for="addAmount">
          Add<br />
          <input id="addAmount" type="number" v-model="addAmount" />
        </label>
      </div>

      <div style="padding: 10px 0; text-align: center;">
        <select v-model="addUnit">
          <option value="seconds">Seconds</option>
          <option value="minutes">Minutes</option>
          <option value="hours">Hours</option>
        </select>
      </div>

      <div class="button-row">
        <button @click="addValue">Save</button>
        <button @click="state = ''" class="is-secondary">Cancel</button>
      </div>
    </div>
    <div v-else-if="loaded" class="timer--content">
      <a @click="state = 'deleting'" class="delete-link" href="#">Delete...</a>
      <input
        type="text"
        class="invisible-input"
        v-model="label"
        @change="saveLabel"
      />
      <ClockFace :started_at="started_at" :store="store" :ticking="ticking" />
      <Ticker :ticking="ticking" />
      <div class="button-row">
        <SexyButton v-if="!ticking" @click="startTimer">Start timer</SexyButton>
        <SexyButton v-else @click="stopTimer">Stop timer</SexyButton>
        <SexyButton @click="state = 'editing'">Edit</SexyButton>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, getDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore";
// import { doc, getDocs, collection, getDoc, setDoc, updateDoc, onSnapshot, deleteDoc } from "firebase/firestore";
import { db } from "@/firebase/App.js";
import ClockFace from "@/components/ClockFace.vue";
import SexyButton from "@/components/SexyButton.vue";
import Ticker from "@/components/Ticker.vue";

export default {
  components: {
    ClockFace,
    SexyButton,
    Ticker,
  },
  watch: {
    label() {
      document.title = this.label;
    },
  },
  data() {
    return {
      state: "",
      addAmount: 2,
      addUnit: "minutes",
      label: "",
      notFound: false,
      loaded: false,
      started_at: 0,
      store: 0,
      ticking: false,
      docRef: doc(db, "timers", this.$route.params.id),
      isDeleting: false,
    };
  },
  methods: {
    async addThisUser(users) {
      if (!this.$store.state.uid) {
        return;
      }
      if (users.indexOf(this.uid) >= 0) {
        return;
      }
      users.push(this.$store.state.uid);
      await setDoc(
        this.docRef,
        {
          users,
        },
        {
          merge: true,
        }
      );
    },
    async deleteTimer() {
      this.isDeleting = true;
      await deleteDoc(doc(db, "timers", this.$route.params.id));
      this.$router.push("/");
    },
    async stopTimer() {
      const store = this.store + Date.now() - this.started_at;
      await setDoc(
        this.docRef,
        {
          ticking: false,
          store,
          started_at: 0,
        },
        {
          merge: true,
        }
      );
    },
    async addValue() {
      let amount = this.addAmount * 1000;

      if (this.addUnit == "minutes") {
        amount *= 60;
      }
      if (this.addUnit == "hours") {
        amount *= 60;
        amount *= 60;
      }

      await setDoc(
        this.docRef,
        {
          store: this.store + amount,
        },
        {
          merge: true,
        }
      );
      this.state = "";
      this.addUnit = "minutes";
      this.addAmount = 2;
    },
    async saveLabel() {
      await setDoc(
        this.docRef,
        {
          label: this.label,
        },
        {
          merge: true,
        }
      );
    },
    async startTimer() {
      await setDoc(
        this.docRef,
        {
          ticking: true,
          started_at: Date.now(),
        },
        {
          merge: true,
        }
      );
    },
    setData(timer) {
      let data = timer.data();
      console.log("got doc", timer, "setting data", data);
      this.started_at = data.started_at;
      this.ticking = data.ticking;
      this.store = data.store;
      this.label = data.label;
    },
  },
  async mounted() {
    // const allTimers = await getDocs(collection(db, "timers"));
    // allTimers.forEach((timer) => {
    //   // const data = timer.data();
    //   // const userId = data.uid;
    //   updateDoc(
    //     timer.ref,
    //     {
    //       archived: false
    //     },
    //     {
    //       merge: true,
    //     }
    //   );
    // });

    const timer = await getDoc(this.docRef);
    if (!timer.exists()) {
      return (this.notFound = true);
    }
    this.notFound = false;
    onSnapshot(doc(db, "timers", this.$route.params.id), (doc) => {
      this.setData(doc);
    });

    this.loaded = true;
    this.setData(timer);

    //  add the current user if not already there.
    this.addThisUser(timer.data().users);
  },
};
</script>

<style>
.timer {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.timer--content {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
}
.invisible-input {
  box-shadow: none;
  appearance: none;
  border: 1px solid transparent;
  text-align: center;
  padding: 8px;
}

.sticky-header {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}
.sticky-header a {
  padding: 8px;
}
.delete-link {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #ff3b30;
  padding: 8px;
}
.delete-page {
  text-align: center;
}
.delete-page h1 {
  margin-top: 0;
}
</style>
